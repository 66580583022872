<template>
	<div>
		<div class="blue-section-title-wrapper">
			<div class="section-title">{{title}}</div>
			<div class="flex-border"></div>
		</div>
		<div class="blue-slot-wrapper">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title'],
		data() {
			return {
				currentMenuId: null,
			}
		},

		created() {},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.blue-section-title-wrapper {
		height: 50px;
		display: flex;
		align-items: center;
		border-left: 10px #0a39bb solid;
		margin-top: 20px;
		.section-title{
			margin: 0 10px 0 40px;
			font-weight: 700;
		}
		.flex-border{
			flex: 1;
			border-bottom: 1px solid #cccccc;
		}
	}
	.blue-slot-wrapper{
		padding: 20px 20px 20px 50px;
	}

	
</style>
