import { render, staticRenderFns } from "./blue-tag-section-title.vue?vue&type=template&id=12df97fd&scoped=true&"
import script from "./blue-tag-section-title.vue?vue&type=script&lang=js&"
export * from "./blue-tag-section-title.vue?vue&type=script&lang=js&"
import style0 from "./blue-tag-section-title.vue?vue&type=style&index=0&id=12df97fd&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12df97fd",
  null
  
)

export default component.exports