<template>
  <div style="background-color: #fff;padding: 20px;">
    <div style="display: flex;height: 200px;padding: 20px 100px;">
      <div style="min-width: 300px;height: 100%;margin-right: 40px;">
        <img
          src="@/static/images/share/yiqi.png"
          style="width: 100%;height: 100%;object-fit: cover;"
        />
      </div>
      <div
        style="flex: 1;height: 100%;display: flex;flex-direction: column;justify-content: space-between;"
      >
        <div class="h1">{{ detailData.gizName }}</div>
        <div class="h2">所在地址：{{ detailData.address }}</div>
        <div class="h2">投资行业：{{ field}}</div>
      </div>
    </div>
    <div style="background-color: #dbe9fb;padding: 20px;">
      <div class="h2" style="display: flex;align-items: center;justify-content: space-between;">
        <div style="width: 200px;">投资方式</div>
        <div style="width: 200px;">联系人</div>
        <div style="width: 200px;">联系电话</div>
        <div style="width: 200px;">电子邮箱</div>
      </div>
      <div
        class="h1"
        style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;;"
      >
        <div style="width: 200px;">{{ detailData.investmentType=='1'?'直接投资':'其他' }}</div>
        <div style="width: 200px;">{{ detailData.contactName }}</div>
        <div style="width: 200px;">{{ detailData.contactPhone }}</div>
        <div style="width: 200px;">{{ detailData.contactEmail }}</div>
      </div>
    </div>
    <div>
      <blue-tag title="机构简介">
        <div>{{ detailData.introduction }}</div>
      </blue-tag>
      <blue-tag title="投资偏好">
        <div>{{ detailData.investmentPreference }}</div>
      </blue-tag>
      <blue-tag title="创新领域">
        <div>{{ detailData.investmentCase }}</div>
      </blue-tag>
    </div>
  </div>
</template>

<script>
import blueTag from './components/blue-tag-section-title.vue'

export default {
  name: 'Home',
  components: {
    blueTag
  },
  data() {
    return {
      notice_id: null,
      detailData: {}
    }
  },
  computed: {
    field() {
      const v = `${this.detailData?.field ?? 'default'}`
      console.log('vvv = ', v)
      // （1-电子信息技术，2-先进材料，3-装备制造，4-生物医药，6-节能环保，7-汽车行业，8-航空航天技术，9-食品饮料，10-新能源与节能技术，11-其他）
      const dic = {
        '1': '电子信息技术',
        '2': '先进材料',
        '3': '装备制造',
        '4': '生物医药',
        '6': '节能环保',
        '7': '汽车行业',
        '8': '航空航天技术',
        '9': '食品饮料',
        '10': '新能源与节能技术',
        '11': '其他',
        'default': '其他',
      }
      return dic[v] ?? '--'
    },
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.fundGizDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  // text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}
</style>
